import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input('form') form!: FormGroup;
  @Input('label') label!: string;
  @Input('controlName') controlName!: string;
  @Input('value') value!: string | number | boolean;
  @Input('disabled') disabled: boolean = false;
  @Input('customClass') customClass: boolean = false;
  required = false;

  constructor() {}

  ngOnInit(): void {
    if (this.disabled) this.control.disable();
    else this.control.enable();
    if (this.control?.errors?.required === true) this.required = true;
  }

  ngOnChanges(changes:any){
    if(changes?.disabled?.currentValue) this.control.disable();
    else this.control.enable();
  }

  get control() {
    return this.form?.controls[this.controlName];
  }

  get isRequired() {
    return this.control.hasValidator(Validators.required);
  }

  /**
   * Random string of len 5
   * @returns
   */
  private getRandom = () =>
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);

  random = this.getRandom();
}
