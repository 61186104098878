<div
  class="custom-control custom-radio custom-control-inline"
  [ngClass]="{ disabled: disabled }"
  [formGroup]="form"
>
<span
*ngIf="(required && label)"
class="ml-1 mb-1 text-danger"
>
*
</span>
  <input
    [id]="'radio-' + value + random"
    type="radio"
    class="custom-control-input"
    [value]="value"
    [formControlName]="controlName"
    [checked]="control.value === value"
  />
  <label [ngClass]="customClass ? 'custom' : ''" class="custom-control-label d-flex" [for]="'radio-' + value + random">
    <span>{{ label }}</span>
  </label>
</div>
