<div class="position-relative">
  <div class="header">
    <!-- <button
      *ngIf="selectedItems.length"
      matTooltip="Delete selected"
      (click)="deleteSelected()"
      class="btn btn-sm bg-danger"
    >
      <i class="material-icons-outlined text-white">delete</i>
    </button> -->
    <div class="btn-group">
      <button
        type="button"
        class="btn-sm"
        [ngClass]="{
          'btn-enable': isColumnGroupingEnable
        }"
        matTooltip="Group by"
        (click)="onColumnGroupingEnebled()"
      >
        <i class="material-icons-outlined"> table_view </i>
      </button>
      <div
        class="btn-group"
        ngbDropdown
        role="group"
        aria-label="Button group with nested dropdown"
        matTooltip="Select Column"
      >
        <button
          [disabled]="!isColumnGroupingEnable"
          style="margin-left: -4px"
          class="btn btn-sm dropdown-toggle-split"
          ngbDropdownToggle
        ></button>
        <div ngbDropdownMenu aria-labelledby="export" class="selectable">
          <button
            *ngFor="let column of visibleColumns"
            (click)="toggleGroupingColumn(column.prop)"
            ngbDropdownItem
          >
            <ng-container *ngIf="column.name !== 'id'">
              <i
                *ngIf="
                  getSelectedColIndex(column.prop) === payloadSortBy[0]?.column
                "
                class="material-icons-outlined"
                >done</i
              >
              <span>
                {{ column.name | titlecase }}
              </span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>

    <!-- <button
      matTooltip="Select rows"
      (click)="changeSelectable()"
      class="btn btn-sm "
      [ngClass]="{ 'btn-enable': isSelectable }"
    >
      <i class="material-icons-outlined">library_add_check</i>
    </button> -->
    <button
      matTooltip="Column Search"
      (click)="toggleColumnSearch()"
      class="btn btn-sm"
      [ngClass]="{ 'btn-enable': isColumnSearch }"
    >
      <i class="material-icons-outlined">manage_search</i>
      <!-- <span>Column Search</span> -->
    </button>

    <!-- <div class="btn-group">
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{ 'btn-enable': isGroupingSelected }"
        (click)="toggleGrouping()"
        matTooltip="Grouping"
      >
        <i class="material-icons-outlined">groups</i>
      </button>
      <div
        class="btn-group"
        ngbDropdown
        role="group"
        aria-label="Button group with nested dropdown"
        matTooltip="Group by"
      >
        <button
          style="margin-left: -4px"
          class="btn btn-sm dropdown-toggle-split"
          ngbDropdownToggle
        ></button>
        <div ngbDropdownMenu aria-labelledby="export" class="selectable">
          <button
            *ngFor="let column of visibleColumns"
            (click)="groupBy(column.prop)"
            ngbDropdownItem
          >
            <ng-container *ngIf="column.name !== 'id'">
              <i
                *ngIf="
                  getSelectedColIndex(column.prop) === payloadSortBy[0]?.column
                "
                class="material-icons-outlined"
                >done</i
              >
              <span>
                {{ column.name | titlecase }}
              </span>
            </ng-container>
          </button>
        </div>
      </div>
    </div> -->
    <div ngbDropdown>
      <button
        matTooltip="Entries"
        id="page-size"
        ngbDropdownToggle
        class="btn btn-sm"
      >
        <i class="material-icons-outlined">list</i>
        <span>({{ page.size }})</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="page-size" class="selectable">
        <button
          (click)="onPageSizeSelect(size)"
          ngbDropdownItem
          *ngFor="let size of pageSizeOptions"
        >
          <i *ngIf="size === page.size" class="material-icons-outlined">done</i>
          <span>{{ size }}</span>
        </button>
      </div>
    </div>
    <div ngbDropdown>
      <button
        matTooltip="Export"
        id="export"
        ngbDropdownToggle
        class="btn btn-sm"
      >
        <i class="material-icons-outlined">exit_to_app</i>
        <!-- <span>Export</span> -->
      </button>
      <div ngbDropdownMenu aria-labelledby="export">
        <button
          (click)="onExport(option)"
          ngbDropdownItem
          *ngFor="let option of exportOptions"
        >
          {{ option }}
        </button>
      </div>
    </div>
    <button matTooltip="Columns" (click)="openFilters()" class="btn btn-sm">
      <i class="material-icons-outlined">view_column</i>
      <!-- <span>Columns</span> -->
    </button>
  </div>
  <ngx-datatable
    *ngIf="renderTable"
    #myTable
    class="material fullscreen"
    [columnMode]="columnMode"
    [headerHeight]="50"
    [groupRowsBy]="groupByName"
    [footerHeight]="50"
    [rowHeight]="36"
    [scrollbarV]="true"
    [scrollbarH]="true"
    [rows]="rows"
    [virtualization]="false"
    [externalPaging]="true"
    [externalSorting]="true"
    [count]="page.totalElements"
    [offset]="page.pageNumber"
    [limit]="page.size"
    (page)="pageListener($event)"
    (sort)="sortListener($event)"
    (select)="onSelect($event)"
    [selectionType]="SelectionType.checkbox"
    [rowClass]="getRowClass"
  >
    <ngx-datatable-group-header *ngIf="isGrouping" [rowHeight]="50">
      <ng-template
        let-group="group"
        let-expanded="expanded"
        ngx-datatable-group-header-template
      >
        <div style="padding-left: 5px" *ngIf="!!group.value">
          <a
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            title="Expand/Collapse Group"
            (click)="toggleExpandGroup(group)"
          >
            {{
              fieldToTitle(payloadColumns[payloadSortBy[0].column].data)
                | titlecase
            }}:
            <b
              >{{
                fieldToTitle(
                  payloadColumns[payloadSortBy[0].column].data
                ).includes("datecreated") ||
                fieldToTitle(
                  payloadColumns[payloadSortBy[0].column].data
                ).includes("datemodified")
                  ? convertToDate(group.key)
                  : (group.key ||
                      "(No " +
                        fieldToTitle(
                          payloadColumns[payloadSortBy[0].column].data
                        ) +
                        ")" | titlecase)
              }}
              ({{ getTotalRecords(group.value) }})</b
            >
          </a>
        </div>
      </ng-template>
    </ngx-datatable-group-header>

    <!-- Action column -->
    <ngx-datatable-column
      *ngIf="actions"
      name="Actions"
      [frozenLeft]="pinActions"
      [draggable]="false"
      [sortable]="false"
      [maxWidth]="200"
      [checkboxable]="false"
      [headerCheckboxable]="false"
      [cellClass]="getCellClass"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <ng-container *ngIf="row.colSearch">
          <div class="d-flex justify-content-center">Search</div>
        </ng-container>
        <ng-container *ngIf="!row.colSearch">
          <ng-container *ngIf="groupedByColumn && row?.isgrouped == 'true'">
            TOT ({{ row?.tot }} records)
          </ng-container>
          <ng-container *ngIf="groupedByColumn && row?.isgrouped == 'false'">
            <ng-container
              *ngTemplateOutlet="
                actions;
                context: {
                  row: row,
                  reportId: dataTableReportId,
                  tableId: tableId
                }
              "
            ></ng-container>
          </ng-container>
          <ng-container *ngIf="!groupedByColumn">
            <ng-container
              *ngTemplateOutlet="
                actions;
                context: {
                  row: row,
                  reportId: dataTableReportId,
                  tableId: tableId
                }
              "
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>

    <!-- Other columns -->
    <ngx-datatable-column
      *ngFor="let col of visibleColumns"
      [name]="col.name | titlecase"
      [prop]="col.prop || col.name | lowercase"
      [width]="col.width || 200"
      [comparator]="noClientSort"
    >
      <ng-template
        let-column="column"
        let-row="row"
        let-value="value"
        ngx-datatable-cell-template
      >
        <ng-container *ngIf="row.colSearch">
          {{ value }}
          <input
            (keyup.enter)="emitPayload()"
            (keyup)="onColumnSearch($event, column)"
            placeholder="Search {{ column.name }}"
            class="col-search-ip"
            type="text"
          />
        </ng-container>
        <ng-container *ngIf="!row.colSearch">
          <ng-container *ngIf="isHyperlink(column)">
            <a [routerLink]="routeLink(row, column)">
              <!-- {{getValue(column, value)}} -->
              <ng-container
                *ngTemplateOutlet="
                  selectTemplate(value);
                  context: { column: column, value: value }
                "
              ></ng-container>
            </a>
          </ng-container>
          <ng-container *ngIf="isFile(column)">
            <a class="link" (click)="fileLink(row, column)">
              <!-- {{getValue(column, value)}} -->
              <ng-container
                *ngTemplateOutlet="
                  selectTemplate(value);
                  context: { column: column, value: value }
                "
              ></ng-container>
            </a>
          </ng-container>
          <ng-container *ngIf="!isHyperlink(column) && !isFile(column)">
            <ng-container
              *ngTemplateOutlet="
                selectTemplate(value);
                context: { column: column, value: value }
              "
            ></ng-container>
            <!-- {{ getValue(column, value) }} -->
          </ng-container>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <div class="page-count">
          <ng-container *ngIf="isColumnSearch">
            <ng-container *ngIf="isSelectable">
              {{ selectedItems.length }} selected /
            </ng-container>
            {{ (rowCount - 1).toLocaleString() }} total
          </ng-container>
          <ng-container *ngIf="!isColumnSearch">
            <ng-container *ngIf="isSelectable">
              {{ selectedItems.length }} selected /
            </ng-container>
            {{ rowCount.toLocaleString() }} total
          </ng-container>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>
  <!-- <app-loader [state]="loadingState"></app-loader> -->
</div>

<!-- Custom templates to render column value in like in case of boolean render checkbox -->
<ng-template #checkbox let-column="column" let-value="value">
  <div
    class="
      custom-control custom-checkbox custom-checkbox-new custom-checkbox-un-01
    "
  >
    <input
      [checked]="value === 'True'"
      type="checkbox"
      class="custom-control-input"
    />
    <label class="custom-control-label"></label>
  </div>
</ng-template>

<!-- Fallback template -->
<ng-template #default let-column="column" let-value="value">
  <div
    [ngStyle]="{ textAlign: valueAlign(column) }"
    [matTooltip]="getValue(column, value)"
    [innerHTML]="getValue(column, value)"
  >
    <!-- {{ getValue(column, value) }} -->
  </div>
</ng-template>
