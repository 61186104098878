import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input('form') form!: FormGroup;
  @Input('label') label!: string;
  @Input('controlName') controlName!: string;
  // type = toggle if toggle button is required
  @Input('type') type: 'toggle' | 'check' = 'check';
  @Output('change') change = new EventEmitter<any>();
  // in case form is not passed use this flag to mark checkbox as checked
  @Input('checked') checked: boolean = false;
  @Input('disabled') disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Random string of len 5
   * @returns
   */
  private getRandom = () =>
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);

  random = this.getRandom();

  get isRequired() {
    return this.form.controls[this.controlName].hasValidator(Validators.required);
  }
}
