<div
  class="right-side-nav right-side-nav-progressbar"
  [ngClass]="sidebarDesktop ? 'collapse-menu' : ''"
  [ngClass]="sidebarMobile ? 'active' : ''"
>
  <div class="right-side-nav-inner">
    <div class="nav-logo-section">
      <a [routerLink]="routeLink" class="dash-logo">
        <img [src]="logoSRCLG" class="logo-one w-50 m-auto" alt="" />
        <img [src]="logoSRCSM" class="logo-two" alt="" />
        <span class="desktop-hidden text-logo">SSBCI</span>
      </a>
      <div class="btn-collapse-nav">
        <a
          (click)="toggleDesktop()"
          [ngClass]="sidebarDesktop ? 'collapsed-menu' : ''"
          class="button-collapse shadow"
          id="collapse-nav"
          ><i class="fe fe-chevron-right"></i
        ></a>
      </div>

      <!--  mobile  -->
      <div class="mobile-menu-div desktop-hidden">
        <button
          class="navbar-toggler mobile-menu-button"
          (click)="toggleMobile()"
          id="menu-open"
          type="button"
        >
          <span class="navbar-toggler-icon"> <i class="fe fe-menu"></i> </span>
        </button>
      </div>
      <!--  End of mobile  -->
    </div>

    <div
      class="right-side-menu"
      id="dashboard-menu-clone"
      [ngClass]="sidebarMobile ? 'active' : ''"
    >
      <div class="right-side-inner">
        <!--  mobile  -->
        <a
          href="javascript:void(0)"
          class="closebtn desktop-hidden"
          (click)="toggleMobile()"
          id="menu-closed"
        >
          <i class="fe fe-x"></i>
        </a>
        <!--  End of mobile  -->

        <div class="group-user-drop-nav-list">
          <div class="menu-cloned-one" id="menu-cloned-one">
            <div class="nav-dashboard-right nav-dashboard-new-custom-right">
              <div class="user-drop-down">
                <ul class="dropdown drop-left dropdown-custom-top">
                  <li
                    class="btn dropdown-toggle"
                    routerLink=""
                    routerLinkActive="active"
                  >
                    <a>
                      <div class="user-profile">
                        <!-- <div class="user-img">
                            <img
                              src="assets/images/user.png"
                              class="user-top-image"
                              alt="user image"
                            />
                          </div> -->
                        <div class="user-info w-100 px-0">
                          <!-- <h3>{{ user?.Email }}</h3> -->
                          <p>{{ user?.Email }}</p>
                        </div>
                      </div>
                    </a>
                  </li>
                  <!-- <li
                    class="btn dropdown-toggle text-white"
                    routerLinkActive="active"
                    (click)="goToProfile()"
                  >
                    <a>
                      <span class="fe fe-edit"></span>
                      <span class="text ml-3" *ngIf="!sidebarDesktop"
                        >Edit Profile</span
                      >
                    </a>
                  </li> -->
                  <li
                    class="btn dropdown-toggle text-white"
                    routerLinkActive="active"
                    (click)="logout()"
                  >
                    <a>
                      <span class="common-icon icon-Logout"></span>
                      <span class="text ml-3" *ngIf="!sidebarDesktop"
                        >Logout</span
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="side-nav-item accordion" id="menu-collapse">
            <ul class="main-listing">
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <a
                  class="nav-link nav-toggle collapsed"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="category-three"
                  matTooltip="Home"
                  matTooltipPosition="after"
                  routerLink=""
                >
                  <span class="icon-nav">
                    <img
                      style="width: 20px"
                      src="assets/images/icons/default-app-icons/home.png"
                    />
                  </span>
                  <span class="text-collapse-menu">Home </span>
                </a>
              </li>
              <ng-container *ngIf="userRole === 'Applicant'">
                <li class="nav-item" routerLinkActive="active">
                  <a
                    class="nav-link nav-toggle collapsed"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="category-three"
                    matTooltip="Applications"
                    matTooltipPosition="after"
                    routerLink="/applicant"
                  >
                    <span class="icon-nav">
                      <img
                        style="width: 20px"
                        src="assets/images/icons/default-app-icons/community.png"
                      />
                    </span>
                    <span class="text-collapse-menu"
                      >Applications
                    </span>
                  </a>
                </li>
              </ng-container>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <div style="height: 56px;">&nbsp;</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  mobile  -->
    <div id="cd-shadow-layer" class="menu-closed backdrop-menu-layer"></div>
    <!--  End of mobile  -->
  </div>
</div>
