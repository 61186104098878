<div
  *ngIf="type === 'check'"
  class="
    custom-control custom-checkbox custom-checkbox-new custom-checkbox-un-01
  "
  [formGroup]="form"
>
  <input
    type="checkbox"
    class="custom-control-input"
    [id]="'checkbox-' + controlName + random"
    [formControlName]="controlName"
    [attr.disabled]="disabled ? true : null"
  />
  <label class="custom-control-label d-flex" [for]="'checkbox-' + controlName + random"
    ><span>{{ label }}</span>
    <span *ngIf="isRequired" class="ml-1 mb-1 text-danger"> * </span></label
  >
</div>

<div *ngIf="type === 'toggle' && form" class="form-group" [formGroup]="form">
  <div class="switch-slider focus-none">
    <div *ngIf="label" class="col-md-6">
      <span class="text ml-0">{{ label }}</span>
    </div>
    <div class="col-md-6">
      <input
        type="checkbox"
        class="toggle"
        [id]="'checkbox-' + controlName + random"
        [formControlName]="controlName"
        [attr.disabled]="disabled ? true : null"
      />
      <label [for]="'checkbox-' + controlName + random">
        <span class="on">ON</span>
        <span class="off">OFF</span>
      </label>
    </div>
  </div>
</div>

<div *ngIf="type === 'toggle' && !form" class="form-group">
  <div class="switch-slider focus-none">
    <div *ngIf="label" class="col-md-6">
      <span class="text ml-0">{{ label }}</span>
    </div>
    <div class="col-md-6">
      <input
        type="checkbox"
        class="toggle"
        [checked]="checked"
        [id]="'checkbox-' + random"
        [attr.disabled]="disabled ? true : null"
      />
      <label [for]="'checkbox-' + random">
        <span class="on">ON</span>
        <span class="off">OFF</span>
      </label>
    </div>
  </div>
</div>
